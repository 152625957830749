<template>
    <ion-page data-pageid="tab1">
        <ion-content>
            <!-- <div
                style="
                    position: fixed;
                    top: 50%;
                    white-space: break-spaces;
                    word-break: break-all;
                "
            >
                {{ rs }} rs
            </div>
            <div
                style="
                    position: fixed;
                    top: 70%;
                    white-space: break-spaces;
                    word-break: break-all;
                "
            >
                {{ version }} version {{ MaUngDung }} MaUngDung
            </div> -->

            <TrangChuVue :data="data" :model="model">
                <template #LenhDienTu>
                    <div class="my-3">
                        <TrangChuLenhDangThucHienVue
                            ref="TrangChuLenhDangThucHien"
                            :model="LenhHienTai"
                            :ThongTinChuyenDi="ThongTinChuyenDi"
                            @reloadDanhSachLenhDangThucHien="
                                reloadDanhSachLenhDangThucHien
                            "
                        />
                    </div>
                </template>
            </TrangChuVue>

            <DxPopup
                v-if="ifPopupCapNhatPhienBan"
                v-model:visible="PopupCapNhatPhienBan"
                width="90%"
                height="auto"
                :show-title="false"
                :close-on-outside-click="false"
                :drag-enabled="false"
                :show-close-button="false"
                @hidden="onHiddenPopup('PopupCapNhatPhienBan')"
            >
                <PopupCapNhatPhienBanVue
                    :Params="ParamsCapNhatPhienBan"
                    @DongPopup="CapNhatPhienBan"
                />
            </DxPopup>
        </ion-content>
    </ion-page>
</template>

<script>
import TrangChuVue from "../components/_Common/TrangChu";
import TrangChuLenhDangThucHienVue from "../components/TrangChuLenhDangThucHien";
import TaiKhoan from "../../class/TaiKhoan";
import {
    IonFooter,
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    onIonViewWillEnter,
} from "@ionic/vue";
import PopupCapNhatPhienBanVue from "../components/_Common/PopupCapNhatPhienBan.vue";
import { DxPopup } from "devextreme-vue";
export default {
    components: {
        TrangChuVue,
        TrangChuLenhDangThucHienVue,
        IonFooter,
        IonPage,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        PopupCapNhatPhienBanVue,
        DxPopup,
    },
    data() {
        return {
            LenhHienTai: {
                GioXuatBenKH: null,
                BienKiemSoat: null,
                MaLenh: null,
                TenTuyen: null,
                MaTuyen: null,
                SoKhach: null,
                TrangThai: null,
                MaMau: null,
                IdTrangThai: null,
                QrCode: null,
                IdLenh: null,
            },
            ThongTinChuyenDi: {},
            data: {
                ChucNang: [],
                QuangCao: [],
            },
            model: {
                TenTaiKhoan: "",
            },
            rs: "",
            ParamsCapNhatPhienBan: {
                Message: "Bạn cần cập nhật phiên bản mới để tiếp tục sử dụng ứng dụng!",
                CoPhienBanMoi: true,
                BatBuocCapNhat: true,
                LinkCapNhat: "",
                CapNhatIos: true,
                SoPhienBanMoi: "",
            },
            ifPopupCapNhatPhienBan: false,
            PopupCapNhatPhienBan: false,
        };
    },
    computed: {
        version() {
            return navigator && navigator.frontendVersion
                ? navigator.frontendVersion
                : null;
        },
        MaUngDung() {
            return navigator && navigator.frontendAppCode
                ? navigator.frontendAppCode
                : null;
        },
        isAndroid() {
            var ua = navigator.userAgent.toLowerCase();
            return ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        },
        isIos() {
            return (
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            );
        },
    },

    methods: {
        onHiddenPopup(name) {
            this["if" + name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        dieuHuong(item) {
            this.$router.push({
                path: item.link,
            });
        },
        async getDataLenh() {
            this.ThongTinChuyenDi = {};
            this.LenhHienTai = {};
            try {
                this.$startLoading;

                let rs = await this.$Core.Api.LenhMobile(
                    this.$t("url.LayLenhHienTaiCuaLaiXe"),
                ).Get();
                this.$stopLoading;
                let lenhHienTaiCuaLaiXe = rs.Data.data;
                if (rs.Data.status === true) {
                    this.LenhHienTai.GioXuatBenKH =
                        lenhHienTaiCuaLaiXe.thoiGianXuatBenKeHoach;
                    this.LenhHienTai.BienKiemSoat = lenhHienTaiCuaLaiXe.bienKiemSoat;
                    this.LenhHienTai.MaLenh = lenhHienTaiCuaLaiXe.maLenh;
                    this.LenhHienTai.TenTuyen = lenhHienTaiCuaLaiXe.tenTuyen;
                    this.LenhHienTai.MaTuyen = lenhHienTaiCuaLaiXe.maTuyen;
                    this.LenhHienTai.SoKhach = lenhHienTaiCuaLaiXe.soKhach;
                    this.LenhHienTai.TrangThai = lenhHienTaiCuaLaiXe.trangThai;
                    this.LenhHienTai.MaMau = lenhHienTaiCuaLaiXe.maMauTrangThai;
                    this.LenhHienTai.QrCode = lenhHienTaiCuaLaiXe.qrCode;
                    this.LenhHienTai.laBenDi = lenhHienTaiCuaLaiXe.laBenDi;
                    this.LenhHienTai.benDi = lenhHienTaiCuaLaiXe.benDi;
                    this.LenhHienTai.benDen = lenhHienTaiCuaLaiXe.benDen;
                    this.LenhHienTai.IdTrangThai = lenhHienTaiCuaLaiXe.idTrangThai;
                    this.LenhHienTai.IdLenh = lenhHienTaiCuaLaiXe.idLenh;
                    this.LenhHienTai.LaLenhGiay = lenhHienTaiCuaLaiXe.laLenhGiay;
                    this.LenhHienTai.MaCDBCap = lenhHienTaiCuaLaiXe.maCDBCap;
                    this.LayThongTinChuyenDiTheoLenh(lenhHienTaiCuaLaiXe.idLenh);
                }
            } catch (error) {
                console.error(error);
                this.$stopLoading;
            }
        },
        async LayThongTinChuyenDiTheoLenh(idLenhDienTu) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTin/LayThongTinChuyenDiTheoLenh",
                    idLenhDienTu,
                );
                if (rs.Data.status === true) {
                    this.ThongTinChuyenDi = rs.Data.data;
                } else {
                    this.ThongTinChuyenDi = {};
                }
            } catch (error) {
                this.ThongTinChuyenDi = {};
                console.error(error);
            }
        },
        async getDataVe() {
            this.model.TenTaiKhoan = new TaiKhoan().hoTen;
            //Lấy thông tin quảng cáo
            let thongTinChucNang = await new TaiKhoan().layThongTinChucNang();
            // this.data.ChucNang = thongTinChucNang || []
            this.data.ChucNang = [];
            let DsChucNang = [
                {
                    id: 1,
                    maMau: "#FEAEAA",
                    viTri: 1,
                    chucNangUrl: "/DNVT-Quan-Ly-Lenh",
                    icon: "script-text-outline",
                    tenChucNang: "Quản lý lệnh",
                    quyen: {
                        client: "LENHDIENTU",
                        role: "DanhSachLenh"
                    },
                },
                {
                    id: "LenhTuyChon",
                    maMau: "#FEAEAA",
                    viTri: 2,
                    chucNangUrl: "/DNVT-Quan-Ly-Lenh/Tao-Lenh-Tuy-Chon",
                    icon: "script-text-outline",
                    tenChucNang: "Tạo lệnh tùy chọn",
                    quyen: {
                        client: "LENHDIENTU",
                        role: "TaoLenhTuyChon"
                    },
                },
                {
                    id: 2,
                    maMau: "#FEAEAA",
                    viTri: 2,
                    chucNangUrl: "/Bao-Cao-Su-Dung-Lenh",
                    icon: "script-text-outline",
                    tenChucNang: "BC lái xe sử dụng lệnh",
                    quyen: null,
                },
                {
                    id: 3,
                    maMau: "#FEAEAA",
                    viTri: 3,
                    chucNangUrl: "/Bao-Cao/Danh-Sach-Chuyen-Di-Cua-Lai-Xe",
                    icon: "script-text-outline",
                    tenChucNang: "BC lái xe bán vé",
                    quyen: null,
                },
                {
                    id: 4,
                    maMau: "#FEAEAA",
                    viTri: 4,
                    chucNangUrl: "/Quan-Ly-Lai-Xe",
                    icon: "script-text-outline",
                    tenChucNang: "QL Lái xe",
                    quyen: {
                        client: "LENHDIENTU",
                        role: "DanhSachLaiXe"
                    },
                },
                {
                    id: 5,
                    maMau: "#FEAEAA",
                    viTri: 5,
                    chucNangUrl: "/Quan-Ly-Tuyen",
                    icon: "script-text-outline",
                    tenChucNang: "QL Tuyến",
                    quyen: {
                        client: "LENHDIENTU",
                        role: "DanhSachTuyenVanChuyen"
                    },
                },
                {
                    id: 6,
                    maMau: "#FEAEAA",
                    viTri: 6,
                    chucNangUrl: "/Quan-Ly-Xe",
                    icon: "script-text-outline",
                    tenChucNang: "QL Xe",
                    quyen: {
                        client: "LENHDIENTU",
                        role: "DanhSachXe"
                    },
                },
            ];
            DsChucNang.forEach((e) => {
                if (
                    !e.quyen ||
                    (e.quyen &&
                        this.$Helper.KiemTraQuyen(
                            this.$t("QuyenLenhDienTu." + e.quyen.client),
                            this.$t("QuyenLenhDienTu." + e.quyen.role),
                        ))
                ) {
                    this.data.ChucNang.push(e);
                }
            });
            //Lấy thông tin quảng cáo
            let thongTinQuangCao = await new TaiKhoan().layThongTinQuangCao();
            this.data.QuangCao = thongTinQuangCao || [];
            //Lấy thông tin cấu hình chung khi cache chưa có
            let check = false;
            let rs = await new TaiKhoan().layCauHinhChung();
            let CauHinhChung = new TaiKhoan().cauHinhChung();
            if (CauHinhChung) {
                for (const key in CauHinhChung) {
                    if (Object.hasOwnProperty.call(CauHinhChung, key)) {
                        const value = CauHinhChung[key];
                        if (value != rs[key]) {
                            check = true;
                            break;
                        }
                    }
                }
            } else {
                //Lưu vào cache
                localStorage.setItem(
                    "ThongTinCauHinhChung",
                    JSON.stringify(rs.Data.data) ||
                        JSON.stringify({
                            soChoToiDa: 3,
                            soLuongKetQua: 20,
                            soNgayTimKiem: 7,
                            thoiGianQuayLaiTrangChu: 120,
                            thoiGianQuetMaQR: 120,
                        }),
                );
            }
            if (check) {
                //Lưu vào cache
                localStorage.setItem(
                    "ThongTinCauHinhChung",
                    JSON.stringify(rs.Data.data),
                );
            }
        },
        async KiemTraCapNhat() {
            try {
                let query = {
                    MaUngDung: this.MaUngDung,
                    HeDieuHanh: this.isAndroid ? "Android" : this.isIos ? "IOS" : null,
                    SoPhienBan: this.version,
                };
                let rs = await this.$store.dispatch(
                    "ThongTin/LayThongTinPhienBan",
                    query,
                );
                this.rs = "1: " + JSON.stringify(rs.Data.data);
                if (rs.Data.status == true) {
                    if (
                        !this.$Core.AuthApi.TokenParsed.hasOwnProperty(
                            "DuocPhepCapNhat",
                        ) ||
                        (this.$Core.AuthApi.TokenParsed.hasOwnProperty(
                            "DuocPhepCapNhat",
                        ) &&
                            this.$Core.AuthApi.TokenParsed.DuocPhepCapNhat != false)
                    ) {
                        this.rs += "\n2: " + rs.Data.data.BatBuocCapNhat;
                        if (rs.Data.data.BatBuocCapNhat) {
                            this.rs += "\n3: vào";
                            this.showPopup("PopupCapNhatPhienBan");
                            this.ParamsCapNhatPhienBan.LinkCapNhat =
                                rs.Data.data.LinkCapNhat;
                            this.ParamsCapNhatPhienBan.SoPhienBanMoi =
                                rs.Data.data.SoPhienBanMoi;
                        }
                    }
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    "kiểm tra cập nhật phiên bản gặp lỗi! " + error,
                );
            }
        },
        CapNhatPhienBan() {
            this.PopupCapNhatPhienBan = false;
            try {
                (async (_) => {
                    try {
                        this.$startLoading;
                        var a = await SonPhat.forceUpdate(
                            {
                                app_name: this.MaUngDung,
                                version: this.ParamsCapNhatPhienBan.SoPhienBanMoi,
                            },
                            this.ParamsCapNhatPhienBan.LinkCapNhat,
                        );
                        this.rs += "\n4" + JSON.stringify(a);
                        if (a) location.reload();
                    } catch (error) {
                        console.log("🚀 ~ error:", error);
                        this.$Helper.ThongBaoToast(
                            "error",
                            "Cập nhật phiên bản gặp lỗi! " + error,
                        );
                    } finally {
                        this.$stopLoading;
                    }
                })();
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        reloadDanhSachLenhDangThucHien() {
            this.getDataLenh();
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.$startLoading;
            await this.KiemTraCapNhat();
            await this.$refs.TrangChuLenhDangThucHien.layChiTietLenhDangThucHienAction();
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                arrDichVu.forEach((e) => {
                    if (e == this.$t("StringCheck.SuDungVe")) {
                        this.getDataVe();
                    }
                    if (e == this.$t("StringCheck.SuDungLenh")) {
                        this.getDataLenh();
                    }
                });
            } else {
                await this.getDataVe();
                await this.getDataLenh();
            }
            this.$stopLoading;
        });
    },
};
</script>
<style lang="scss" scoped></style>
